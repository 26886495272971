import * as React from "react"
import Template from "../components/Template"
import SEO from "../components/SEO"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import Container from "react-bootstrap/Container"
import Collapse from "react-bootstrap/Collapse"
import Alert from "react-bootstrap/Alert"
import axios from 'axios';



const SubirPropiedad= (props) => {
const [open, setOpen] = React.useState(false);
const [form, setForm] = React.useState({
    modalidad: "Venta",
    nombre:"",
    telefono:"",
    correo:"",
    ubicacion: "",
    descripcion: "",
    precio: "",
    tipo: "",
    banos:"",
    dormitorios:"",
    pisos:"",
    superficieTerreno:"",
    superficieConstruccion:"",
    superficiePrivada:"",
    estacionamientos:"",
    anoConstruccion:"",
    estrato:""
})
const handleChange=(e)=>{
    let valor
    if (e.target.type==="radio"){
        valor=e.currentTarget.value
    }else{
        valor=e.target.value
    }
    const campo=e.target.name
    setForm(prevState=>{
        return {
            ...prevState,
            [campo]: valor
        }
    })
}
const handleSubmit=(e)=>{
    e.preventDefault()
    document.getElementById("submitPublicar").disabled="true"
    
   axios.post(`/.netlify/functions/publicar-propiedad`, { 
        contactName: form.nombre,
        contactEmail: form.correo,
        contactNumber: form.telefono,
        modalidad: form.modalidad,
        ubicacion: form.ubicacion,
        descripcion: form.descripcion,
        precio:form.precio,
        tipo:form.tipo,
        banos:form.banos,
        dormitorios:form.dormitorios,
        pisos:form.pisos,
        superficieTerreno:form.superficieTerreno,
        superficieConstruccion:form.superficieConstruccion,
        superficiePrivada:form.superficiePrivada,
        estacionamientos:form.estacionamientos,
        anoConstruccion:form.anoConstruccion,
        estrato: form.estrato,
    }).then(res=>{
        document.getElementById("submitPublicar").removeAttribute('disabled')
        if (typeof window !== 'undefined'){
            window.alert("Mensaje enviado")
        }
    })
}
  return (
    <Template>
      <SEO title="Subir Propiedad"/>
      
          <Container style={{marginTop: "2em"}}>
           <Row className="justify-content-center">
                  <Col lg={6} md={8} sm={10} xs={12}>
                  <h1 style={{textAlign: "center"}}>¡Publica con nosotros!</h1>
                  
          <p style={{marginTop: "2em", textAlign: "center"}}>{props.data.sanityGlobalConfig.textPublicaConNosotros}</p>
             
                  <Form className="formSubirPropiedad" onSubmit={handleSubmit}>
                  <Form.Group controlId="form.modalidad" style={{textAlign: "center"}}>
                        <Form.Check inline name="modalidad" value="Venta" onClick={handleChange} checked={form.modalidad==="Venta"} label="Quiero vender" type="radio" id={`inline-radio-1`} />
                        <Form.Check inline name="modalidad" value="Arriendo" onClick={handleChange} checked={form.modalidad!=="Venta"} label="Quiero arrendar" type="radio" id={`inline-radio-2`} />       
                    </Form.Group>
                    <Form.Group controlId="form.nombre">
                        <Form.Control type="text" name="nombre" value={form.nombre} onChange={handleChange} required  placeholder="Su nombre"/>
                    </Form.Group>
                    <Form.Group controlId="form.correoElectronico">
                        <Form.Control type="email" name="correo" value={form.correo} onChange={handleChange} required  placeholder="Correo electrónico" />
                    </Form.Group>
                    
                    <Form.Group controlId="form.telefono">
                        <Form.Control type="number" name="telefono" value={form.telefono} onChange={handleChange} required  placeholder="Teléfono" />
                    </Form.Group>
                    
                    <Form.Group controlId="form.ubicacion">
                        <Form.Control type="text" name="ubicacion" value={form.ubicacion} onChange={handleChange} required  placeholder="Ubicación" />
                    </Form.Group>
                    <Form.Group controlId="form.descripcion">
                        <Form.Control as="textarea" name="descripcion" value={form.descripcion} onChange={handleChange} required  rows={4} placeholder="Descripción de su propiedad" />
                    </Form.Group>
                    <Form.Group controlId="form.submit" style={{textAlign:"center"}}>
                        <Button block 
                            size="lg" 
                            type="button" 
                            variant="primary" 
                            style={{backgroundColor:"var(--primary)", 
                            color: "var(--brightText)", 
                            border: 0 }}
                            onClick={() => setOpen(!open)}
                            aria-controls="moreDetails"
                            aria-expanded={open}
                            >Agregar más detalles (opcionales)</Button>
                    </Form.Group>
                    <Collapse in={open}>
                    <div id="moreDetails">
                        <Form.Group controlId="form.nombre">
                            <Form.Control type="number" name="precio" value={form.precio} onChange={handleChange} placeholder="Precio estimado"/>
                        </Form.Group>
                        <Form.Group controlId="form.nombre">
                            <Form.Control type="text" name="tipo" value={form.tipo} onChange={handleChange}  placeholder="Tipo de propiedad"/>
                        </Form.Group>
                        <Row>
                            <Col sm={4} xs={12}>
                                <Form.Control type="number" name="banos" value={form.banos} onChange={handleChange}  placeholder="Baños"/>
                            </Col>
                            <Col sm={4} xs={12}>
                                <Form.Control type="number" name="dormitorios" value={form.dormitorios} onChange={handleChange}  placeholder="Dormitorios"/>
                            </Col>
                            <Col sm={4} xs={12}>
                                <Form.Control type="number" name="pisos" value={form.pisos} onChange={handleChange}  placeholder="Pisos"/>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4} xs={12}>
                                <Form.Control type="number" name="superficieTerreno" value={form.superficieTerreno} onChange={handleChange} placeholder="Terreno (M2)"/>
                            </Col>
                            <Col sm={4} xs={12}>
                                <Form.Control type="number" name="superficieConstruccion" value={form.superficieConstruccion} onChange={handleChange}  placeholder="Construcción (M2)"/>
                            </Col>
                            <Col sm={4} xs={12}>
                                <Form.Control type="number" name="superficiePrivada" value={form.superficiePrivada} onChange={handleChange}  placeholder="Privado (M2)"/>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4} xs={12}>
                                <Form.Control type="number" name="estacionamientos" value={form.estacionamientos} onChange={handleChange}  placeholder="Estacionamientos"/>
                            </Col>
                            <Col sm={4} xs={12}>
                                <Form.Control type="number" name="anoConstruccion" value={form.anoConstruccion} onChange={handleChange}  placeholder="Año construccion"/>
                            </Col>
                            <Col sm={4} xs={12} style={{marginBottom: "1em"}}>
                                <Form.Control type="number" name="estrato" value={form.estrato} onChange={handleChange}  placeholder="Estrato"/>
                            </Col>
                        </Row>
                    </div>
                    </Collapse>
                    <Form.Group controlId="form.submit" style={{textAlign:"center"}}>    
                        <Button block size="lg" type="submit" id="submitPublicar" variant="primary" style={{backgroundColor:"var(--secondary)", color: "var(--darkText)", border: 0}}>¡Envía tus datos!</Button>
                        
                    </Form.Group>
                    </Form>
                    
                </Col>
            </Row>
        </Container>
                    
    </Template>
  )
}


export default SubirPropiedad


export const query = graphql`
  query{
    sanityGlobalConfig {
      textPublicaConNosotros
    }
  }
`

